import React, { useContext } from 'react'
import classes from './OobeBar.module.scss'
import PrinterAvatar from '../PrinterAvatar/PrinterAvatar'
import { ConfigContext } from '../../store/ConfigContext'
import Stepper from '../Stepper/Stepper'

const OobeBar = () => {
  const { isHpPlus } = useContext(ConfigContext)

  return (
    <div className={`${classes.bar} ${classes.globalVars}`}>
      <div className={classes['avatar-area']}>
        <div className={classes.corner}>
          <div
            className={`${classes.top} ${!isHpPlus ? classes.traditional : ''}`}
            data-testid="top-color"
          />
          <div className={classes.bottom} />
        </div>
        <PrinterAvatar />
      </div>
      <Stepper />
    </div>
  )
}

export default OobeBar
