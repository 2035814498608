import React from 'react'
import OobeBar from './OobeBar'
import TdBar from '../TdLayout/TdBar'
import ConfigProvider from '../../store/ConfigContext'

const OobeLayout = (props) => {
  const getExperience = () => {
    if (props?.sidebarProps && props.sidebarProps?.sidebar) {
      return props.sidebarProps?.sidebar?.experience
    }
    return null
  }
  return (
    <ConfigProvider {...props}>
      {getExperience() === 'OC' ? <TdBar /> : <OobeBar />}
    </ConfigProvider>
  )
}

export default OobeLayout
