import React from 'react'
import OobeLayout from '../src/components/OobeLayout'
import { ShellProps } from '../src/types/shell'
import packageInfo from '../package.json'
import styled from 'styled-components'

export const Section = styled.section`
  height: 100%;
`

declare global {
  interface Window {
    Shell: ShellProps
  }
}

/**
 * @function Root Main function
 * @returns
 * @param sidebarProps
 */
export default function Root(sidebarProps) {
  const {
    v1: { store, localization }
  } = window.Shell as ShellProps

  return (
    <Section id={packageInfo.name}>
      <OobeLayout
        store={store}
        localization={localization}
        sidebarProps={sidebarProps}
      />
    </Section>
  )
}
