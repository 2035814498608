import React, { useContext, useState, useEffect } from 'react'
import { ConfigContext } from '../../store/ConfigContext'
import VeneerStepper from '@veneer/core/dist/scripts/stepper'
import classes from './Stepper.module.scss'

const STEPPER_ID = 'veneer-stepper'
const VERTICAL = 'vertical'
const HORIZONTAL = 'horizontal'
const SCREEN_LARGE_SIZE = 767

const buildStepperData = (sessionStepperData, copy) => {
  let stepperData = []
  for (let step of sessionStepperData) {
    stepperData.push({
      label: <div className={classes.label}>{copy[step.stepId]}</div>,
      status: step.status,
      step: step.order
    })
  }
  return stepperData
}

const Stepper = () => {
  const { sessionContext, getTextTree } = useContext(ConfigContext)
  const [steps, setSteps] = useState([])
  const [stepperDirection, setStepperDirection] = useState(
    document.documentElement.clientWidth > 767 ? VERTICAL : HORIZONTAL
  )
  useEffect(() => {
    if (sessionContext?.stepperState) {
      let stepperData = getTextTree('stepper')
      setSteps(buildStepperData(sessionContext.stepperState, stepperData))
    }
  }, [getTextTree, sessionContext?.stepperState])

  useEffect(() => {
    const handleStepperDirectionOnResize = () => {
      const isLargeScreen =
        document.documentElement.clientWidth > SCREEN_LARGE_SIZE
      setStepperDirection(isLargeScreen ? VERTICAL : HORIZONTAL)
    }

    window.addEventListener('resize', handleStepperDirectionOnResize)
    handleStepperDirectionOnResize()
    return () => {
      window.removeEventListener('resize', handleStepperDirectionOnResize)
    }
  }, [])

  return (
    <div className={classes.stepper}>
      <VeneerStepper
        id={STEPPER_ID}
        className={classes.veneerStepper}
        direction={stepperDirection}
        appearance="compact"
        steps={steps}
      />
    </div>
  )
}

export default Stepper
