import React, { useContext, useMemo } from 'react'
import CommonGhostAvatar from '../../assets/GhostPrinters/common.svg'
import LargeFormatGhostAvatar from '../../assets/GhostPrinters/large_format.svg'
import ECPGhostAvatar from '../../assets/GhostPrinters/ecp.svg'
import classes from './PrinterAvatar.module.scss'
import { ConfigContext } from '../../store/ConfigContext'
import clsx from 'clsx'

const PrinterAvatar = () => {
  const {
    getTextTree,
    isLfp,
    isEcpExperience,
    isHpPlus,
    avatarData
  } = useContext(ConfigContext)

  const copy = useMemo(
    () => getTextTree(isHpPlus ? 'avatar.e2e' : 'avatar.traditional'),
    [getTextTree, isHpPlus]
  )

  const getPrinterImage = () => {
    if (avatarData?.printerImage) return avatarData.printerImage
    if (isLfp) return LargeFormatGhostAvatar
    if (isEcpExperience) return ECPGhostAvatar
    return CommonGhostAvatar
  }

  return (
    <div className={clsx(classes.avatar, { [classes.lfp]: isLfp })}>
      <div className={classes.printerArea}>
        <img
          src={getPrinterImage()}
          alt={'printer'}
          data-testid={`printer-img${isLfp ? '-lfp' : ''}${
            isEcpExperience ? '-ecp' : ''
          }`}
        />
      </div>
      <section className={classes.details} data-testid="printer-avatar">
        <h2>
          {avatarData.name || avatarData.modelName || copy.placeholder_name}
        </h2>
        {avatarData?.location && (
          <p className={classes.location}>{avatarData.location}</p>
        )}
        {avatarData?.name && (
          <p className={classes.model}>{avatarData.modelName}</p>
        )}
        {avatarData?.organization && <p>{avatarData.organization}</p>}
      </section>
    </div>
  )
}

export default PrinterAvatar
