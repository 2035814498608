import React from 'react'
import classes from './TdBar.module.scss'
import TdStepper from '../TdStepper/TdStepper'

const TdBar = () => {
  return (
    <div className={classes.bar}>
      <TdStepper />
    </div>
  )
}

export default TdBar
