// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2S7hqZvnO0zAE8igzLQE7o {\n  padding-inline-start: 20px;\n  border-inline-start: 1px solid #ADADAD; }\n  ._2S7hqZvnO0zAE8igzLQE7o div:empty {\n    margin-block: 8px;\n    min-height: 5px; }\n  ._2S7hqZvnO0zAE8igzLQE7o .label {\n    position: static; }\n  ._2S7hqZvnO0zAE8igzLQE7o .step {\n    margin-inline-end: 12px; }\n", "",{"version":3,"sources":["webpack://./src/components/TdStepper/TdStepper.module.scss"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,sCAAsC,EAAE;EACxC;IACE,iBAAiB;IACjB,eAAe,EAAE;EACnB;IACE,gBAAgB,EAAE;EACpB;IACE,uBAAuB,EAAE","sourcesContent":[".stepper {\n  padding-inline-start: 20px;\n  border-inline-start: 1px solid #ADADAD; }\n  .stepper div:empty {\n    margin-block: 8px;\n    min-height: 5px; }\n  .stepper :global(.label) {\n    position: static; }\n  .stepper :global(.step) {\n    margin-inline-end: 12px; }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stepper": "_2S7hqZvnO0zAE8igzLQE7o"
};
export default ___CSS_LOADER_EXPORT___;
