import React, { useContext } from 'react'
import { ConfigContext } from '../../store/ConfigContext'
import VeneerStepper from '@veneer/core/dist/scripts/stepper'
import classes from './TdStepper.module.scss'

const STEPPER_ID = 'veneer-stepper'

const TdStepper = () => {
  const { customSidebar, stepperClickHandler } = useContext(ConfigContext)

  if (!customSidebar) return <> </>
  return (
    <div className={classes.stepper} id="stepper">
      <VeneerStepper
        id={STEPPER_ID}
        customStyle={'height: auto'}
        direction="vertical"
        appearance="standard"
        steps={customSidebar}
        interactive={!!stepperClickHandler}
        onChange={stepperClickHandler}
      />
    </div>
  )
}

export default TdStepper
