// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2kTjUvEVVqBqCMhd-FegLc {\n  font-family: \"HP Simplified Light\", \"HP Simplified ME Light\", Arial, sans-serif;\n  color: #212121;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  background: #F8F8F8;\n  height: 100%; }\n", "",{"version":3,"sources":["webpack://./src/components/TdLayout/TdBar.module.scss"],"names":[],"mappings":"AAAA;EACE,+EAA+E;EAC/E,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,mBAAmB;EACnB,YAAY,EAAE","sourcesContent":[".bar {\n  font-family: \"HP Simplified Light\", \"HP Simplified ME Light\", Arial, sans-serif;\n  color: #212121;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  background: #F8F8F8;\n  height: 100%; }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bar": "_2kTjUvEVVqBqCMhd-FegLc"
};
export default ___CSS_LOADER_EXPORT___;
