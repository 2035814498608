import React, { useEffect, useMemo, useState } from 'react'
import AssetsProviderFactory from '../assets/AssetsProviderFactory'

const OD_KEY_MAPPING = {
  modelName: 'modelName',
  bizModel: 'bizModel',
  deviceLocation: 'location',
  deviceName: 'name',
  highestResImgUrl: 'printerImage'
}

const defaultAvatar = {
  bizModel: null,
  printerImage: null,
  name: null,
  modelName: null,
  location: null,
  organization: null,
  programLevel: null
}

export const ConfigContext = React.createContext({
  country: null,
  language: null,
  avatarData: defaultAvatar,
  sessionContext: {},
  stageContext: {},
  customSidebar: null,
  sidebar: null,
  experience: null,
  stepperClickHandler: null,
  getText: null,
  getTextTree: null,
  isHpPlus: false,
  isEcpExperience: false,
  isLfp: false
})

const FALLBACK_SIDEBAR = {
  customSidebar: null,
  experience: null,
  stepperClickHandler: null
}
const ConfigProvider = (props) => {
  const {
    sidebarProps = {
      sidebar: FALLBACK_SIDEBAR
    },
    store = null,
    localization = { country: 'us', language: 'en' }
  } = props
  localization.country = localization.country.toUpperCase()
  const { country, language } = localization
  const { sidebar } = sidebarProps
  const { customSidebar, experience, stepperClickHandler } =
    sidebar || FALLBACK_SIDEBAR
  const [avatarData, setAvatarData] = useState(defaultAvatar)
  const [sessionContext, setSessionContext] = useState({})
  const [stageContext, setStageContext] = useState({})
  const assetsProvider = AssetsProviderFactory.create(language, country)
  const updateAvatarWithSessionData = () => {
    let changed = false
    let updatedContext = { ...avatarData }
    if (stageContext) {
      for (let k of Object.keys(stageContext)) {
        if (stageContext[k] !== null) {
          updatedContext[k] = stageContext[k]
          changed = true
        }
      }
    }
    if (
      sessionContext?.onboardingContext &&
      sessionContext?.onboardingContext?.programLevel &&
      !avatarData?.programLevel
    ) {
      updatedContext['programLevel'] =
        sessionContext.onboardingContext.programLevel
      changed = true
    }
    if (sessionContext?.device) {
      for (let k of Object.keys(sessionContext.device)) {
        if (
          OD_KEY_MAPPING[k] &&
          sessionContext.device[k] !== null &&
          avatarData[OD_KEY_MAPPING[k]] !== sessionContext.device[k]
        ) {
          updatedContext[OD_KEY_MAPPING[k]] = sessionContext.device[k]
          changed = true
        }
      }
    }
    if (
      sessionContext?.tenant &&
      sessionContext?.tenant?.accountName &&
      avatarData['organization'] !== sessionContext.tenant.accountName
    ) {
      updatedContext['organization'] = sessionContext.tenant.accountName
      changed = true
    }
    if (changed) {
      setAvatarData(updatedContext)
    }
  }

  const isHpPlus = useMemo(
    () => avatarData?.programLevel?.toLowerCase?.() === 'hpplus',
    [avatarData]
  )

  const isEcpExperience = useMemo(
    () =>
      sessionContext?.onboardingContext?.experience?.toLowerCase?.() === 'ecp',
    [sessionContext]
  )

  const isLfp = useMemo(
    () =>
      sessionContext?.onboardingContext?.productFamily?.toUpperCase?.() ===
      'LFP_DUNE',
    [sessionContext]
  )

  useEffect(() => {
    updateAvatarWithSessionData()
  }, [sessionContext, stageContext])

  if (
    store?.state?.onboarding?.sessionContext &&
    JSON.stringify(sessionContext) !==
      JSON.stringify(store.state.onboarding.sessionContext)
  ) {
    setSessionContext(store.state.onboarding.sessionContext)
  }

  if (
    store?.state?.onboarding?.stageContext &&
    JSON.stringify(stageContext) !==
      JSON.stringify(store.state.onboarding.stageContext)
  ) {
    setStageContext(store.state.onboarding.stageContext)
  }

  const configState = {
    country,
    language,
    avatarData,
    sessionContext,
    customSidebar,
    stepperClickHandler,
    experience,
    sidebarProps,
    isHpPlus,
    isEcpExperience,
    isLfp,
    getText: (key, keys = {}) => {
      return assetsProvider.getText(key, keys)
    },
    getTextTree: (key) => {
      return assetsProvider.getTextTree(key)
    }
  }
  return (
    <ConfigContext.Provider value={configState}>
      {props.children}
    </ConfigContext.Provider>
  )
}

export default ConfigProvider
